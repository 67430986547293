<template>
  <div class="tw-flex tw-flex-col tw-gap-1">
    <div class="pipe">
      <div class="label-wrapper">
        <div class="label">{{ teacher }}</div>

        <div class="percentage">
          {{ percentageLabel }}
        </div>
      </div>

      <div class="percentage-bar"></div>
    </div>

    <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-px-2">
      <template v-if="hasChanges.length">
        <div
          v-for="{ amount, color, icon } in hasChanges"
          class="tw-flex tw-items-center tw-gap-2 tw-rounded-full tw-px-2 tw-py-[2px]"
          :class="[`tw-bg-${color}/15`]"
        >
          <q-icon :color :name="`fas ${icon}`" size="10px" />

          <span class="tw-text-xs"> {{ amount }} </span>
        </div>
      </template>

      <template v-if="!hasChanges.length">
        <span class="tw-col-span-full tw-text-xs">Geen wijzigingen</span>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
  teacher: {
    type: String,
    required: true,
    default: () => "",
  },
  syncData: {
    type: Object,
    required: true,
    default: () => {},
  },
});

const hasChanges = computed(() => {
  const { new: newAmount, deleted } = props.syncData;

  return [
    ...(deleted > 0
      ? [
          {
            amount: `${deleted} verwijderd`,
            color: "negative",
            icon: "fa-minus",
          },
        ]
      : []),
    ...(newAmount > 0
      ? [
          {
            amount: `${newAmount} toegevoegd`,
            color: "positive",
            icon: "fa-plus",
          },
        ]
      : []),
  ];
});

const percentageAmount = computed(() => {
  const { filled, total } = props.syncData;

  return Math.round((filled / total) * 100);
});

const percentageLabel = computed(() => {
  const { filled, total } = props.syncData;

  const percentageString = `${percentageAmount.value}%`;
  const fragment = `(${filled} van ${total})`;

  return `${percentageString} ${fragment}`;
});
</script>

<style lang="scss" scoped>
.pipe {
  @apply tw-grid tw-items-center tw-overflow-hidden tw-rounded-full tw-border tw-border-slate-200;
  grid-template-columns: repeat(100, [col] 1fr);
  background: repeating-linear-gradient(
    -45deg,
    rgb(var(--color-striped-bg-light)),
    rgb(var(--color-striped-bg-light)) 5px,
    rgb(var(--color-striped-bg-dark)) 5px,
    rgb(var(--color-striped-bg-dark)) 10px
  );

  .label-wrapper {
    @apply tw-z-10 tw-col-span-full tw-flex tw-justify-between tw-overflow-hidden tw-px-3 tw-py-1;
    grid-row: 1;

    .label {
      @apply tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-pre tw-text-sm;
    }

    .percentage {
      @apply tw-text-sm;
    }
  }

  .percentage-bar {
    @apply tw-z-0 tw-flex tw-h-full tw-bg-slate-200;
    grid-row: 1;
    grid-column: col / span v-bind(percentageAmount);
  }
}
</style>
