export const LOGIN_AS_EMPLOYEE_PATH = "/webmaster/login-as-employee";
export const LOGIN_AS_STUDENT_PATH = "/webmaster/login-as-student";

export const getRedirectPath = (role) => {
  switch (role) {
    case "employee":
      return LOGIN_AS_EMPLOYEE_PATH;
    case "student":
      return LOGIN_AS_STUDENT_PATH;
    default:
      return LOGIN_AS_EMPLOYEE_PATH;
  }
};
