<template>
  <loading-icon v-if="loading" />

  <div class="tw-flex tw-flex-col tw-gap-4" v-if="!loading">
    <div class="tw-flex tw-flex-col tw-gap-2">
      <h4>Dit gaan we doen:</h4>

      <p class="tw-mb-0">
        De volgende records worden verwijderd en toegevoegd. Weet je zeker dat
        je dit wilt doorvoeren? Deze actie kunnen we niet meer ongedaan maken.
      </p>
    </div>

    <div
      class="tw-grid tw-grid-cols-1 tw-gap-4 sm:tw-grid-cols-2 md:tw-grid-cols-3"
    >
      <teacher-sync-schedule-preview
        v-for="(teacherData, teacher) in syncSchedulesPreviewTeacherData"
        :teacher="teacher"
        :sync-data="teacherData"
      />
    </div>
  </div>
</template>

<script setup>
import axios from "axios";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import TeacherSyncSchedulePreview from "./TeacherSyncSchedulePreview.vue";
import LoadingIcon from "components/loaders/LoadingIcon.vue";

// Router
const route = useRoute();

// Refs
const loading = ref(true);
const syncSchedulesPreviewTeacherData = ref({});

// Methods
const testSyncSchedules = async () => {
  try {
    loading.value = true;

    const { data } = await axios.post(
      `/api/meetings/${route.params.id}/sync-schedules`,
      {},
      { params: { preview: true } },
    );

    Object.assign(syncSchedulesPreviewTeacherData.value, data);
  } catch (error) {
    console.log("🚀 ~ testSyncSchedules ~ error:", error);
  } finally {
    loading.value = false;
  }
};

onMounted(async () => {
  await testSyncSchedules();
});
</script>
