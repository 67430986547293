<template>
  <q-layout class="tw-flex tw-min-h-lvh tw-flex-col">
    <Header />

    <MobileMenu />

    <slot name="headerSpace" />

    <div class="tw-container tw-relative tw-mx-auto tw-flex-1">
      <div
        id="layoutWrap"
        class="tw-grid tw-grid-cols-12 tw-space-x-12 tw-rounded-md"
      >
        <div class="tw-col-span-12">
          <div class="body-container tw-px-4 tw-py-4 tw-text-left">
            <slot />
          </div>
        </div>
      </div>
    </div>

    <CountdownAuthenticator
      v-if="showCountdownModal"
      :timer="timer"
      @reset-timer="resetTimer"
      @logout="logout"
    />

    <CelebrationComponent
      v-if="celebrationVisible"
      :component="celebrationType"
    />

    <BackToTop />

    <Footer />
  </q-layout>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useCelebrationStore } from "store/modules/celebrationStore.js";
import MobileMenu from "components/layout/MobileMenu.vue";

const celebrationStore = useCelebrationStore();
const { celebrationVisible, celebrationType } = storeToRefs(celebrationStore);
</script>

<script>
// TODO: Refactor this to composition API
import { useRoute } from "vue-router";
import axios from "axios";
import CountdownAuthenticator from "@/components/modals/auth/CountdownAuthenticator.vue";
import Header from "@/components/layout/Header.vue";
import Footer from "components/layout/Footer.vue";
import StatusBar from "@/components/layout/StatusBar.vue";
import BackToTop from "@/components/global/BackToTop.vue";
import CelebrationComponent from "components/global/celebration/CelebrationComponent.vue";

export default {
  components: {
    CelebrationComponent,
    CountdownAuthenticator,
    BackToTop,
  },
  setup() {
    const route = useRoute().name;
    return { route };
  },
  data() {
    return {
      minutes: null,
      seconds: null,
      mountedInterval: null,
      showCountdownModal: false,
      countdownText: "",
      isRefreshing: false,
      noInteractionTimer: 0,
      timer: null,
    };
  },
  mounted() {
    // TODO: Put this in a store so it will always works regardless if this component is mounted
    //Refresh token 1 minute before expiry
    let self = this;
    this.minutesLeftUntilRefresh();

    //interval for every 10 seconds
    setInterval(function () {
      self.minutesLeftUntilRefresh();
    }, 10000);

    //interval for no activity
    //Adds +1 every one second, reset on user interaction
    setInterval(function () {
      self.noInteractionTimer = self.noInteractionTimer + 1000; //add one second

      //No activity for 30 minutes
      let maxMinutes = 1800000; //30minutes
      let fiveMinutes = 300000;

      //test
      //maxMinutes = 90000; //1 minutes test 10 second test (1 minute = 60000)

      if (self.noInteractionTimer > maxMinutes - fiveMinutes) {
        let millisLeft = maxMinutes - self.noInteractionTimer; //current - (max + 5 minutes)

        //calculate time left
        let timeLeft = self.millisToMinutesAndSeconds(millisLeft);
        self.timer = timeLeft;
        let minutes = timeLeft[0];
        let seconds = timeLeft[1];

        //Show countdown bar
        self.showCountdownModal = true;

        //Logout if time is expired
        if (minutes < 1 && seconds < 1) {
          self.logout();
        }
      }
    }, 1000);

    //this.countDown(this.buildEndTime());

    document.body.addEventListener("mousemove", this.handleInteraction);
    document.body.addEventListener("scroll", this.handleInteraction);
    document.body.addEventListener("keydown", this.handleInteraction);
    document.body.addEventListener("click", this.handleInteraction);
    document.body.addEventListener("touchstart", this.handleInteraction);
  },
  methods: {
    millisToMinutesAndSeconds(millis) {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);

      return [minutes, (seconds < 10 ? "0" : "") + seconds];
    },
    minutesLeftUntilRefresh() {
      let expiry = this.buildEndTime();
      const total = Date.parse(expiry) - Date.parse(new Date());
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((total / 1000 / 60) % 60);

      //Refresh token if less than 1 minute left
      if (hours < 1 && minutes < 1 && !this.isRefreshing) {
        //alert('refresh')
        this.refresh_token();
      }
    },
    buildEndTime() {
      let expiry = localStorage.getItem("expires_in");
      if (!expiry) return;
      let split = expiry.split(" ");
      let dts = split[0].split("-");
      let tim = split[1].split(":");
      let items = dts.concat(tim);

      return new Date(
        items[2],
        items[1],
        items[0],
        items[3],
        items[4],
        items[5],
      );
    },
    handleInteraction() {
      if (!this.showCountdownModal) {
        this.noInteractionTimer = 0;
      }
      //this.showCountdownBar = false;
    },
    resetTimer() {
      this.noInteractionTimer = 0;
      this.showCountdownModal = false;
    },
    // TODO: Put the refresh_token method in the AuthStore
    refresh_token() {
      if (this.isRefreshing || localStorage.getItem("is_refreshing")) {
        return false;
      }
      console.log("start refreshing token");

      this.isRefreshing = true;
      localStorage.setItem("is_refreshing", "true");

      let self = this;
      axios
        .post("/oauth/token-refresh", {
          refresh_token: localStorage.getItem("refresh_token"),
        })
        .then(function (res) {
          console.log("received refreshing token");

          localStorage.setItem("user_token", res.data.access_token);
          localStorage.setItem("refresh_token", res.data.refresh_token);

          //Update Bearer token for all axios requests
          axios.defaults.headers.common["Authorization"] =
            "Bearer " + res.data.access_token;

          let dt = new Date().getTime();
          dt = new Date(dt + parseInt(res.data.expires_in) * 1000);
          localStorage.setItem("expires_in", dt.toLocaleString());

          console.log("reset (localstorage) is_refreshing");

          //Reset
          localStorage.removeItem("is_refreshing");
          setTimeout(function () {
            self.isRefreshing = false;
          }, 5000);
        });
    },
    logout() {
      localStorage.removeItem("user_token");
      localStorage.removeItem("refresh_token");
      localStorage.clear();

      window.location.reload();
    },
  },
};
</script>
