<template>
  <q-dialog class="modal" ref="dialogRef" :persistent @hide="onDialogHide">
    <q-card class="q-dialog-plugin tw-flex tw-flex-col tw-gap-4 !tw-shadow-xl">
      <q-card-section class="tw-flex tw-items-center tw-gap-4 !tw-px-4 tw-pb-0">
        <div
          v-if="dialogType.icon"
          class="tw-flex tw-aspect-square tw-items-center tw-justify-center tw-rounded-full tw-bg-negative/20 tw-p-[.4rem]"
        >
          <q-icon :class="dialogType.class" :name="dialogType.icon" />
        </div>

        <h3
          class="tw-flex-1 tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-text-lg"
        >
          {{ title }}
        </h3>

        <c-button-icon
          title="Sluiten"
          flat
          icon="fas fa-times"
          @click="onDialogCancel"
        />
      </q-card-section>

      <q-card-section v-if="message" class="tw-overflow-auto !tw-px-4 tw-py-0">
        {{ message }}
      </q-card-section>

      <q-card-section
        v-if="$slots.default"
        class="tw-overflow-auto !tw-px-4 tw-py-0"
      >
        <slot />
      </q-card-section>

      <q-card-actions class="!tw-px-4 tw-pb-4 tw-pt-0" align="right">
        <c-button
          v-if="cancel"
          color="transparent"
          text-color="gray"
          label="Annuleren"
          @click="onDialogCancel"
        />

        <c-button
          :color="dialogType.color"
          :label="primaryLabel"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { computed } from "vue";

const props = defineProps({
  title: {
    type: String,
    required: true,
    default: () => "",
  },
  message: {
    type: [String, null],
    default: () => null,
  },
  cancel: {
    type: Boolean,
    default: () => true,
  },
  persistent: {
    type: Boolean,
    default: () => true,
  },
  primaryLabel: {
    type: String,
    default: () => "",
  },
  type: {
    type: String,
  },
  returnData: {
    type: [String, Object, Array],
    default: "",
  },
  maxWidth: {
    type: String,
    default: () => "560px",
  },
});

defineEmits([...useDialogPluginComponent.emits]);

const dialogType = computed(() => {
  switch (props.type) {
    case "warning":
      return {
        class: "tw-text-warning",
        icon: "fas fa-exclamation-triangle",
        color: "warning",
      };
    case "negative":
      return {
        class: "tw-text-negative",
        icon: "fas fa-exclamation-circle",
        color: "negative",
      };
    case "success":
      return {
        class: "tw-text-success",
        icon: "fas fa-check-circle",
        color: "positive",
      };
    case "info":
      return {
        class: "tw-text-info",
        icon: "fas fa-info-circle",
        color: "primary",
      };
    default:
      return {
        color: "primary",
      };
  }
});

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

defineExpose({ dialogRef });

function onOKClick() {
  onDialogOK(props.returnData);
}
</script>

<style lang="scss" scoped>
.q-dialog.modal {
  .q-card {
    @apply tw-w-full;
    max-width: v-bind(maxWidth);
  }
}
</style>
