import useHelpers, { checkRole } from "composables/useHelpers";
const { mappedTerms } = useHelpers();

const routes = [
  {
    name: "manage",
    path: "/manage",
    redirect: {
      name:
        checkRole("teacher") || checkRole("teamleader") || checkRole("decaan")
          ? "manageLearningObjectives"
          : "manageMeetings",
    },
    meta: {
      title: "Beheer",
      roleRequired: [
        "webmaster",
        "manager",
        "organizer",
        "teamleader",
        "decaan",
      ],
      isMenuItem: true,
      icon: "fa-gears",
      menuOrder: 1,
    },
    children: [
      {
        path: "meetings",
        component: () => import("views/EmptyView.vue"),
        meta: {
          title: "Besprekingen",
          settingRequired: ["studentMeeting"],
          roleRequired: ["webmaster", "manager", "organizer"],
        },
        children: [
          {
            name: "manageMeetings",
            path: "",
            component: () => import("pages/manage/meeting/Meetings.vue"),
            meta: {
              title: "Besprekingen",
              roleRequired: ["webmaster", "manager", "organizer"],
              settingRequired: ["studentMeeting"],
              isSubmenuItem: true,
            },
          },
          {
            path: "add-student-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              title: "Leerlingbespreking toevoegen",
              roleRequired: ["webmaster", "manager", "organizer"],
              settingRequired: ["studentMeeting"],
            },
            children: [
              {
                path: "",
                name: "addStudentMeeting",
                component: () =>
                  import("pages/manage/meeting/AddStudentMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "add-transition-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              title: "Bevorderingsbespreking toevoegen",
              roleRequired: ["webmaster", "manager", "organizer"],
              settingRequired: ["transitionMeeting"],
            },
            children: [
              {
                path: "",
                name: "addTransitionMeeting",
                component: () =>
                  import("pages/manage/meeting/AddTransitionMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "add-group-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              title: "Groepsbespreking toevoegen",
              roleRequired: ["webmaster", "manager", "organizer"],
              settingRequired: ["groupMeeting"],
            },
            children: [
              {
                path: "",
                name: "addGroupMeeting",
                component: () =>
                  import("pages/manage/meeting/AddGroupMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "group-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              title: "Groepsbespreking bewerken",
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            children: [
              {
                path: ":id",
                name: "groupMeeting",
                component: () =>
                  import("pages/manage/meeting/GroupMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "transition-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              title: "Bevorderingsbespreking bewerken",
              roleRequired: ["webmaster", "manager", "organizer"],
              settingRequired: ["transitionMeeting"],
            },
            children: [
              {
                path: ":id",
                name: "transitionMeeting",
                component: () =>
                  import("pages/manage/meeting/TransitionMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
          {
            path: "student-meeting",
            component: () => import("views/EmptyView.vue"),
            meta: {
              title: "Leerlingbespreking bewerken",
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            children: [
              {
                path: ":id",
                name: "studentMeeting",
                component: () =>
                  import("pages/manage/meeting/UpdateMeeting.vue"),
                meta: {
                  hideSubmenu: true,
                },
              },
            ],
          },
        ],
      },
      {
        name: "manageLearningObjectives",
        path: "learning-objectives",
        component: () =>
          import("pages/learning-objectives/ManageLearningObjectives.vue"),
        meta: {
          title: "Leerdoelen",
          isSubmenuItem: true,
          layout: "MainLayout",
          roleRequired: [
            "webmaster",
            "manager",
            "organizer",
            "teamleader",
            "decaan",
            "teacher",
          ],
          settingRequired: ["learningObjectives"],
        },
        children: [
          {
            path: "add",
            name: "manageLearningObjectivesAddCluster",
            component: () =>
              import("pages/learning-objectives/EditCluster.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
              settingRequired: ["learningObjectives"],
            },
          },
          {
            path: ":clusterId",
            name: "manageLearningObjectiveViewCluster",
            component: () =>
              import("pages/learning-objectives/ViewCluster.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
              settingRequired: ["learningObjectives"],
            },
          },
          {
            path: ":clusterId/edit",
            name: "manageLearningObjectiveEditCluster",
            component: () =>
              import("pages/learning-objectives/EditCluster.vue"),
            meta: {
              roleRequired: [
                "webmaster",
                "manager",
                "organizer",
                "teamleader",
                "decaan",
                "teacher",
              ],
              settingRequired: ["learningObjectives"],
            },
          },
        ],
      },
      {
        name: "manageSettings",
        path: "settings",
        meta: {
          title: "Schoolinstellingen",
          isSubmenuItem: true,
          layout: "MainLayout",
          roleRequired: ["webmaster", "manager", "organizer", "decaan"],
        },
        children: [
          {
            path: "general",
            name: "manageSettingsGeneral",
            meta: {
              title: "Algemene instellingen",
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            component: () => import("pages/manage/schoolsettings/General.vue"),
          },
          {
            path: "meetings",
            name: "manageSettingsMeetings",
            meta: {
              title: "Bespreking instellingen",
              inDropdown: true,
              settingRequired: ["studentMeeting"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            component: () => import("pages/manage/schoolsettings/Meetings.vue"),
          },
          {
            path: "portfolio",
            name: "manageSettingsPortfolio",
            meta: {
              title: "Portfolio instellingen",
              inDropdown: true,
              settingRequired: ["portfolio"],
              roleRequired: ["webmaster", "manager", "organizer", "decaan"],
            },
            component: () =>
              import("pages/manage/schoolsettings/Portfolio.vue"),
          },
          {
            path: "learning-objectives",
            name: "mangeSettingsLearningObjectives",
            meta: {
              title: "Leerdoelen instellingen",
              inDropdown: true,
              settingRequired: ["learningObjectives"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            component: () =>
              import("pages/manage/schoolsettings/LearningObjectives.vue"),
          },
          {
            path: "email",
            name: "manageSettingsNotifications",
            meta: {
              title: "E-mail instellingen",
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
            component: () => import("pages/manage/schoolsettings/Email.vue"),
          },
        ],
      },
      {
        path: "data",
        name: "data",
        redirect: {
          name: "ViewStudents",
        },
        meta: {
          title: "Data beheren",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager", "organizer"],
        },
        children: [
          {
            path: "view-students",
            name: "ViewStudents",
            component: () => import("pages/manage/data/ViewStudents.vue"),
            meta: {
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} beheren`,
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "import-students",
            name: "ImportStudents",
            component: () =>
              import("pages/manage/data/import/ImportStudents.vue"),
            meta: {
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} importeren`,
              inDropdown: true,
              settingRequired: ["manageData"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "remove-students",
            name: "RemoveStudents",
            component: () =>
              import("pages/manage/data/import/RemoveStudents.vue"),
            meta: {
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} verwijderen`,
              inDropdown: true,
              settingRequired: ["manageData"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "upload-profile-picture",
            name: "UploadProfilePicture",
            component: () =>
              import("pages/manage/data/UploadProfilePicture.vue"),
            meta: {
              title: "Leerling pasfoto's",
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "view-grades",
            name: "ViewGrades",
            component: () => import("pages/manage/data/ViewGrades.vue"),
            meta: {
              title: "Cijfers inzien",
              inDropdown: true,
              settingRequired: ["gradesEnabled"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "view-employees",
            name: "ViewEmployees",
            component: () => import("pages/manage/data/ViewEmployees.vue"),
            meta: {
              title: "Medewerkers beheren",
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "import-employees",
            name: "ImportEmployees",
            component: () =>
              import("pages/manage/data/import/ImportEmployees.vue"),
            meta: {
              title: "Medewerkers importeren",
              inDropdown: true,
              settingRequired: ["manageData"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "view-schedules",
            name: "ViewSchedules",
            component: () => import("pages/manage/data/ViewSchedules.vue"),
            meta: {
              title: "Lesgroepen bewerken",
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "view-subjects",
            name: "ViewSubjects",
            component: () => import("pages/manage/data/ViewSubjects.vue"),
            meta: {
              title: "Vakken bewerken",
              inDropdown: true,
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "import-schedules",
            name: "ImportSchedules",
            component: () =>
              import("pages/manage/data/import/ImportSchedules.vue"),
            meta: {
              title: "Vakkenpakket importeren",
              inDropdown: true,
              settingRequired: ["manageData"],
              roleRequired: ["webmaster", "manager", "organizer"],
            },
          },
          {
            path: "add-student",
            name: "addStudent",
            component: () => import("pages/manage/data/AddOrUpdateStudent.vue"),
            meta: {
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} toevoegen`,
              roleRequired: ["webmaster", "manager"],
            },
          },
          {
            path: "add-schedule",
            name: "AddSchedule",
            component: () =>
              import("pages/manage/data/AddOrUpdateSchedule.vue"),
            meta: {
              title: "Lesgroep toevoegen",
              roleRequired: ["webmaster", "manager"],
            },
          },
          {
            path: "transfer-students",
            name: "TransferStudents",
            component: () => import("pages/manage/data/TransferStudents.vue"),
            meta: {
              title: `${mappedTerms().students[0].toUpperCase()}${mappedTerms().students.slice(
                1,
              )} verplaatsen`,
              roleRequired: ["webmaster", "manager"],
            },
          },
        ],
      },
      {
        path: "connect",
        name: "connect",
        component: () => import("pages/manage/Connect.vue"),
        meta: {
          title: "Koppeling beheren",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager"],
        },
      },
      {
        path: "schoolsynclog",
        name: "schoolsynclog",
        component: () => import("pages/manage/SchoolSyncLog.vue"),
        meta: {
          title: "Sync Log",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager"],
        },
      },
      {
        path: "schoollog",
        name: "schoollog",
        component: () => import("pages/manage/SchoolLog.vue"),
        meta: {
          title: "Log",
          isSubmenuItem: true,
          roleRequired: ["webmaster", "manager"],
        },
      },
    ],
  },
];

export default routes;
