<template>
  <q-tab class="quasar-menu__tab--small">
    <q-icon
      name="fa-light fa-arrow-right-from-bracket fa-swap-opacity"
      size="20px"
    />
    <q-menu ref="logoutRef" @before-show="handleClick">
      <q-list dense>
        <q-item
          v-for="option in menuItems"
          @click="option.action"
          clickable
          v-close-popup
        >
          <q-item-section>{{ option.name }}</q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-tab>
</template>
<script setup>
import { useMenuStore } from "store/modules/menuStore.js";
import { storeToRefs } from "pinia";
import { useAuthorizationStore } from "store/modules/authorizationStore.js";
import { computed, ref } from "vue";

// Refs
const logoutRef = ref(null);

// menuStore
const menuStore = useMenuStore();
const { logout } = menuStore;

// Authorization Store
const autorizationStore = useAuthorizationStore();
const { returnAsWebmaster, isImpersonating } = autorizationStore;

const menuItems = computed(() => {
  return [
    ...(isImpersonating
      ? [
          {
            name: "Terug naar webmaster account",
            action: returnAsWebmaster,
          },
        ]
      : []),
    {
      name: "Uitloggen",
      action: logout,
    },
  ];
});

const handleClick = (e) => {
  if (!isImpersonating) {
    logoutRef.value.hide();
    logout();
    return;
  }
};
</script>
