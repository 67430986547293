import tailwindTypography from "@tailwindcss/typography";

export default {
  darkMode: "class", // or 'media' or 'class'
  prefix: "tw-",
  content: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  // Use safelist to allow dynamic classes, just in time compiler
  safelist: [
    "!tw-bg-info",
    "!tw-bg-warning",
    "!tw-bg-negative",
    "!tw-bg-positive",
    "tw-bg-info/15",
    "tw-bg-warning/15",
    "tw-bg-negative",
    "tw-bg-positive/15",
    "!tw-bg-positive/5",
    "!tw-border-positive/65",
    "!tw-bg-warning/5",
    "!tw-border-warning/65",
    "!tw-bg-negative/5",
    "tw-bg-negative/15",
    "!tw-border-negative/65",
    "tw-overflow-hidden",
  ],
  theme: {
    container: {
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      fontFamily: {
        cambria: ["Cambria", "sans-serif"],
        heading: ["Chivo", "sans-serif"],
        body: ["Roboto", "sans-serif"],
      },
      // BASE FONT-SIZE 14px
      // FONT SCALE 1.1
      // https://tailbase.samuelhorn.com/
      fontSize: {
        "4xl": ["var(--4xl-font-size)", "var(--4xl-line-height)"],
        "3xl": ["var(--3xl-font-size)", "var(--3xl-line-height)"],
        "2xl": ["var(--2xl-font-size)", "var(--2xl-line-height)"],
        xl: ["var(--xl-font-size)", "var(--xl-line-height)"],
        lg: ["var(--lg-font-size)", "var(--lg-line-height)"],
        md: ["var(--md-font-size)", "var(--md-line-height)"],
        base: ["var(--base-font-size)", "var(--base-line-height)"],
        sm: ["var(--sm-font-size)", "var(--sm-line-height)"],
        xs: ["var(--xs-font-size)", "var(--xs-line-height)"],
        "2xs": ["var(--2xs-font-size)", "var(--2xs-line-height)"],
      },
      colors: {
        lightgray: "#9ca3af",
        "custom-yellow": "rgb(250, 241, 237)",
        grey: {
          default: "#e6e7eb",
        },
        info: "rgb(var(--color-info))",
        warning: "rgb(var(--color-warning))",
        negative: "rgb(var(--color-negative))",
        positive: "rgb(var(--color-positive))",
        "meeting-student": "rgb(var(--color-meeting-student))",
        "meeting-transition": "rgb(var(--color-meeting-transition))",
        "grid-border": "rgb(var(--color-grid-border))",
        "grid-border-group": "rgb(var(--color-grid-border-group))",
        "grid-current-week-indicator":
          "rgb(var(--color-grid-current-week-indicator))",
        primary: {
          DEFAULT: "rgb(var(--color-primary))",
          50: "#f1f5fd",
          100: "#e0e9f9",
          200: "#c8d9f5",
          300: "#a3c0ed",
          400: "#779fe3",
          500: "#577fda",
          600: "#4263ce",
          700: "#3951bc",
          800: "#33439a",
          900: "#2e3c7a",
          950: "rgb(var(--color-primary))",
        },
        secondary: {
          DEFAULT: "rgb(var(--color-secondary))",
          50: "#fef4f2",
          100: "#fee7e2",
          200: "#fdd4cb",
          300: "#fbb5a6",
          400: "#f68a73",
          500: "rgb(var(--color-secondary))",
          600: "#d94829",
          700: "#b63a1f",
          800: "#97331d",
          900: "#7d301f",
          950: "#44150b",
        },
        tertiary: {
          DEFAULT: "rgb(var(--color-tertiary))",
          50: "#f0fbfb",
          100: "#d9f4f4",
          200: "#b7e9ea",
          300: "#d3eae9",
          400: "rgb(var(--color-tertiary))",
          500: "#30a1aa",
          600: "#2b838f",
          700: "#296b75",
          800: "#295861",
          900: "#264a53",
          950: "#143138",
        },
      },
      transitionDuration: {
        0: "0ms",
        2000: "2000ms",
        5000: "5000ms",
      },
      transitionDelay: {
        0: "0ms",
        2000: "2000ms",
        5000: "5000ms",
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [tailwindTypography],
};
