import { h, render } from "vue";

// Drawer can access the app context
let appContext = null;

// HTML tag to render the drawer
const SELECTOR_TAG = "div";
export const CLOSE_ANIMATION_DURATION = 300;
export const CLOSE_ANIMATION_DURATION_IN_MS = `${CLOSE_ANIMATION_DURATION}ms`;

// Install function
export default {
  install: (app) => {
    appContext = app._context;
  },
};

export const openDrawer = () => {
  if (!appContext) {
    throw new Error(
      "openDrawer must be used after the drawer plugin is installed.",
    );
  }

  return ({ component, options }) => {
    return new Promise((resolve) => {
      // Create a new container to render the drawer
      const container = document.createElement(SELECTOR_TAG);

      // Add the container to the body
      document.body.appendChild(container);

      // Prevent scrolling on the body
      document.body.classList.add("tw-overflow-hidden");

      // Create the drawer component using the component and options
      const vnode = h(component, {
        ...options,
        onClose: () => {
          resolve();
          cleanup();
        },
      });

      // Pass the app context to the vnode
      vnode.appContext = appContext;

      // Cleanup function to remove the drawer
      const cleanup = async () => {
        document.body.classList.remove("tw-overflow-hidden");

        // Wait for the close animation to finish
        await new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, CLOSE_ANIMATION_DURATION);
        });

        // Remove the container
        render(null, container);
        container.remove();
      };

      render(vnode, container);
    });
  };
};

// This composable can be used to open the drawer
export const useOpenDrawer = () => {
  const drawer = openDrawer();

  return { openDrawer: drawer };
};
