<template>
  <div class="tw-flex tw-items-center tw-justify-center tw-gap-2">
    <q-pagination
      flat
      gutter=".5em"
      active-design="unelevated"
      v-model="model.page"
      :max="model.last_page"
      boundary-numbers
      :max-pages="6"
      v-bind="$attrs"
      @update:model-value="modelUpdate"
    />
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

const model = defineModel({
  type: Object,
  default: () => {},
  required: true,
});

const emit = defineEmits(["update:model-value"]);

const router = useRouter();
const route = useRoute();

onMounted(() => {
  checkPageParam();
});

const modelUpdate = () => {
  setPageQueryParam();
  emit("update:model-value", model.value);
};

const setPageQueryParam = async () => {
  const { page } = model.value;

  await router.replace({
    query: {
      ...route.query,
      page,
    },
  });
};

const checkPageParam = async () => {
  for (const [key, value] of Object.entries(route.query)) {
    if (key === "page") {
      model.value.page = parseInt(value);
    }
  }
};
</script>
